.hidden {
    display: none;
    text-align: center;
}
.listgroupItem{
    position: relative;
    display: block;
    padding: 0px 15px; /* adjust here */
    margin-bottom: -1px;
    border: 1px solid #ddd;
    line-height: 1em /* set to text height */
}
.headerrow{
    background-color: lightblue;
}
.datarow{
    background-color:whitesmoke;
    border-style: solid;
    border-color:white;
    border-width: 1;
}
.rowbad{
    background-color:#fca596;
    border-style: solid;
    border-color:white;
    border-width: 1px;
}
.col{
    flex-wrap: nowrap;
    text-align: left;
}
.cellwarn1{
    border-style: solid;
    border-color:rgb(245, 224, 185);
    border-width: 1px;
}
.cellwarn2{
    border-style: solid;
    border-color:orange;
    border-width: 1px;
}
.cellerror{
    border-style: solid;
    border-color:red;
    border-width: 1px;
}
