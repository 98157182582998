.response{
    border: 1px solid;
    font-size:x-large;
}
.responseTitle{
    text-align:center;
}
.error{
    padding: 30px;
    text-align: center;
    background-color: red;
    border-color: red;
    opacity: 0.8;
    border-radius: 10px;
}

.listgroupItem{
    position: relative;
    display: block;
    padding: 0px 15px; /* adjust here */
    margin-bottom: -1px;
    border: 1px solid #ddd;
    line-height: 1em /* set to text height */}