h1{
    text-align: center;
}

h3{
    text-align: center;
}

#nextPage{
    display: flex;
    float: left;
    margin-left: 10px;
}

#previousPage{
    display: flex;
    float: right;
    margin-right: 10px;
}

.listgroupItem{
    position: relative;
    display: block;
    padding: 0px 15px; /* adjust here */
    margin-bottom: -1px;
    border: 1px solid #ddd;
    line-height: 1em /* set to text height */
}
.headerrow{
    background-color: lightblue;
}
.datarow{
    background-color:whitesmoke;
    border-style: solid;
    border-color:white;
    border-width: 1;
}
.col{
    flex-wrap: nowrap;
    text-align: left;
}

.attention{
    background: paleturquoise;
}