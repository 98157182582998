.header {
    font-size: 1.5em;
    text-align: center;
    font-family: "Lucida Console", "Courier New", monospace;

}

.navbar{
    padding-bottom: 10px;
    margin-bottom: 50px;
}